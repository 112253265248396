import React from "react";
import Container from "../Container";
import { Helmet } from "react-helmet";

export default function Layout({ children }) {
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Selected Wisdom</title>
      </Helmet>
      <main>
        <Container>{children}</Container>
      </main>
    </>
  );
}
