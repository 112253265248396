require('./src/styles/global.css');
require('./src/styles/typography.css');

const React = require('react');
const Layout = require('./src/components/Layout').default;

const { QueryClient, QueryClientProvider } = require('react-query');

// Wraps every page in a component
exports.wrapPageElement = ({ element, props }) => {
  return <Layout {...props}>{element}</Layout>;
};

exports.wrapRootElement = ({ element }) => {
  const queryClient = new QueryClient();

  return (
    <QueryClientProvider client={queryClient}>{element}</QueryClientProvider>
  );
};
